<template>
  <div
    @click="gotoLink"
    class="link-btn"
  >
    <a
      :href="url"
      target="_blank"
      rel="noopener noreferrer"
    >
      <span v-if="type == 1">
        <img
          src="../assets/img/medium.png"
          alt
        />
        <span>MEDIUM</span>
      </span>
      <span v-else-if="type == 2">
        <img
          src="../assets/img/twitter.png"
          alt
        />
        <span>TWITTER</span>
      </span>
      <span v-else-if="type == 3">
        <img
          src="../assets/img/telegram.png"
          alt
        />
        <span>TELEGRAM</span>
      </span>
      <span v-else-if="type == 4">
        <img
          src="../assets/img/discord.png"
          alt
        />
        <span>DISCORD</span>
      </span>
      <span v-else-if="type == 5">
        <img
          src="../assets/img/tradingview.png"
          alt
        />
        <span>TRADINGVIEW</span>
      </span>
    </a>
  </div>
</template>

<script>
export default {
  name: "Social Media",
  props: {
    type: Number,
    url: String
  },
  methods: {
    gotoLink() {
      if (url) {
        window.open(url, "_blank");
      }
    }
  }
};
</script>

<style lang="less" scoped>
.link-btn {
  color: #fff;
  font-size: 18px;
  margin-right: 10px;
  > a {
    text-decoration-line: none;
    > span {
      display: flex;
      align-items: center;
      > img {
        width: 32px;
        margin-right: 5px;
      }
    }
  }
}

@media (max-width: 980px) {
  .link-btn {
    font-size: 14px;
    & > span > img {
      width: 22px;
      margin-right: 5px;
    }
  }
}
</style>