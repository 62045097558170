<template>
  <span class="asset-icon">
    <span v-if="tokenInfo">
      <img :src="tokenInfo.logoURI" :alt="asset" />
    </span>
  </span>
</template>

<script>
import { getTokenInfo } from "../assets/js/tokenListCDN";

export default {
  name: "Asset Icon",
  props: {
    asset: String
  },
  computed: {
    tokenInfo() {
      return getTokenInfo(this.asset);
    }
  }
};
</script>

<style lang="less" scoped>
.asset-icon {
  > span {
    display: inline-block;
    width: 30px;
    padding-top: 30px;
    position: relative;
    > img {
      position: absolute;
      top: 0%;
      height: 100%;
      width: 100%;
    }
  }
}
</style>
