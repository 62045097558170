<template>
  <span class="asset-icon">
    <span v-if="imgUrl">
      <img :src="imgUrl" :alt="asset" />
    </span>
  </span>
</template>

<script>
export default {
  name: "Asset Icon",
  props: {
    asset: String,
    imgUrl: String
  }
};
</script>

<style lang="less" scoped>
.asset-icon {
  > span {
    display: inline-block;
    width: 30px;
    padding-top: 30px;
    position: relative;
    > img {
      position: absolute;
      top: 0%;
      height: 100%;
      width: 100%;
    }
  }
}
</style>
