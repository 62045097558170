<template>
  <div class="view">
    <div class="back-btn" @click="gotoPools">
      <i class="el-icon-back"></i>
    </div>
    <div class="inner">
      <div class="box1">
        <div class="logo">
          <img
            :src="thisProduct.managerImgUrl"
            v-if="thisProduct.managerImgUrl != ''"
          />
        </div>
        <div class="name">
          <div class="title">
            {{ computedProductName }}
          </div>
          <div class="address">
            {{ thisProduct.mint2 }}
            <i
              class="el-icon-document-copy"
              style="cursor: pointer"
              v-clipboard:success="onCopy"
              v-clipboard:copy="thisProduct.mint2"
            ></i>
          </div>
        </div>
      </div>
      <el-row class="box2">
        <el-col class="item" :span="4" :xs="24">
          {{ thisProduct.managerName }}
        </el-col>
        <el-col class="item" :span="4" :xs="24">$ {{ marketCap }}</el-col>
        <el-col class="item" :span="4" :xs="24">
          {{ init_date }}
        </el-col>
        <el-col class="item" :span="4" :xs="24">
          {{ thisProduct.risk }}
        </el-col>
      </el-row>
      <div class="box3">
        <div class="title">
          <div class="price"></div>
          <div class="btn-box">
            <span class="pc">Show BTC comparison:</span>
            <span class="mb">BTC</span>
            <el-switch
              style="margin: 0 10px"
              v-model="showBTC"
              @change="addBTC"
              active-color="#409EFF"
              inactive-color="#A8A8A8"
            ></el-switch>
            <div class="switch-group">
              <span
                class="switch-btn"
                @click="changeRange(0)"
                :class="{ at: dateWindow == 'hour' }"
              >
                1H
              </span>
              <span
                class="switch-btn"
                @click="changeRange(1)"
                :class="{ at: dateWindow == 'day' }"
              >
                1D
              </span>
              <span
                class="switch-btn"
                @click="changeRange(2)"
                :class="{ at: dateWindow == 'week' }"
              >
                1W
              </span>
              <span
                class="switch-btn"
                @click="changeRange(3)"
                :class="{ at: dateWindow == 'month' }"
              >
                1M
              </span>
              <span
                class="switch-btn"
                @click="changeRange(4)"
                :class="{ at: dateWindow == 'year' }"
              >
                1Y
              </span>
            </div>
          </div>
        </div>
        <div id="price-chart" style="height: 400px" />
      </div>
      <div class="box4">
        <el-row :gutter="25">
          <el-col :span="8">
            <div class="dialog-btn btn-c" @click="openDialog('MINT')">Mint</div>
          </el-col>
          <el-col :span="8">
            <div class="dialog-btn btn" @click="openDialog('BURN')">Burn</div>
          </el-col>
          <el-col :span="8">
            <div class="dialog-btn btn" @click="jumpToLink">Trade</div>
          </el-col>
        </el-row>
      </div>
      <div class="box5">
        <div class="title">Portfolios</div>
        <el-table
          :data="protfolioData"
          style="width: 100%; height: 100%"
          header-row-class-name="header_table"
          row-class-name="row_table"
          header-cell-class-name="header_cell_table"
          cell-class-name="cell_table"
          class="ranking_table1 mbfs12"
        >
          <el-table-column prop="asset" label="ASSET" min-width="90px">
            <template slot-scope="scope">
              <div class="pf-asset-box">
                <asset-icon
                  :asset="scope.row.asset"
                  :imgUrl="scope.row.img"
                  class="pfcoinIcon"
                ></asset-icon>
                <span>{{ scope.row.asset }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="PRICE" min-width="80px">
            <template slot-scope="scope"
              >$ {{ scope.row.price.toFixed(3) }}</template
            >
          </el-table-column>
          <el-table-column label="BALANCE" min-width="85px">
            <template slot-scope="scope">{{
              scope.row.balance.toFixed(4)
            }}</template>
          </el-table-column>
          <el-table-column label="DAILY CHANGE" min-width="150px">
            <template slot-scope="scope">
              <span
                :class="{
                  red: scope.row.change < 0,
                  green: scope.row.change >= 0,
                }"
              >
                {{ (scope.row.change * 100).toFixed(3) }} %
              </span>
            </template>
          </el-table-column>
          <el-table-column label="DISTRIBUTION" min-width="150px">
            <template slot-scope="scope"
              >{{ (scope.row.info * 100).toFixed(3) }} %</template
            >
          </el-table-column>
          <template slot="empty">
            <div class="noData">No available Data</div>
          </template>
        </el-table>
      </div>
      <div class="box5" v-if="positionData.length > 0">
        <div class="title">Positions</div>
        <el-table :data="positionData">
          <el-table-column label="INSTRUMENT">
            <template slot-scope="scope">{{ scope.row.symbol }}</template>
          </el-table-column>
          <el-table-column label="QTY.">
            <template slot-scope="scope">
              <span
                :class="{
                  red: scope.row.holding < 0,
                  green: scope.row.holding >= 0,
                }"
              >
                {{ scope.row.holding }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="AVG. OPEN">
            <template slot-scope="scope">
              {{ scope.row.average_open_price }}
            </template>
          </el-table-column>
          <el-table-column label="MARK">
            <template slot-scope="scope">{{ scope.row.mark_price }}</template>
          </el-table-column>
          <el-table-column label="PNL">
            <template slot-scope="scope">
              <span
                :class="{
                  red:
                    (scope.row.mark_price - scope.row.average_open_price) *
                      scope.row.holding <
                    0,
                  green:
                    (scope.row.mark_price - scope.row.average_open_price) *
                      scope.row.holding >=
                    0,
                }"
              >
                {{
                  (
                    (scope.row.mark_price - scope.row.average_open_price) *
                    scope.row.holding
                  ).toFixed(3)
                }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="24H REAL">
            <template slot-scope="scope">
              <span
                :class="{
                  red: scope.row.pnl_24_h < 0,
                  green: scope.row.pnl_24_h >= 0,
                }"
              >
                {{ scope.row.pnl_24_h }}
              </span>
            </template>
          </el-table-column>
          <el-table-column label="NOTIONAL">
            <template slot-scope="scope">
              {{
                (Math.abs(scope.row.holding) * scope.row.mark_price).toFixed(3)
              }}
            </template>
          </el-table-column>
          <template slot="empty">
            <div class="noData">No available Data</div>
          </template>
        </el-table>
      </div>

      <div class="box6">
        <el-tabs value="about">
          <el-tab-pane :label="'\xa0\xa0' + 'About' + '\xa0\xa0'" name="about">
            <el-row :gutter="20" type="flex" align="middle">
              <el-col :span="7">
                <div class="label">Description</div>
              </el-col>
              <el-col :span="17">
                <p class="content" v-html="thisProduct.description"></p>
                <img
                  v-if="thisProduct.descriptionImg"
                  class="desc-img"
                  :src="thisProduct.descriptionImg"
                />
              </el-col>
            </el-row>
            <div class="line"></div>
            <el-row :gutter="20" type="flex" align="middle">
              <el-col :span="7">
                <div class="label">Inception Date</div>
              </el-col>
              <el-col :span="17">
                <div class="content">
                  <span>{{ init_date }}</span>
                </div>
              </el-col>
            </el-row>
            <div class="line"></div>
            <el-row :gutter="20" type="flex" align="middle">
              <el-col :span="7">
                <div class="label">Manager</div>
              </el-col>
              <el-col :span="17">
                <div class="content">
                  <img
                    v-if="thisProduct.managerImgUrl"
                    class="manager-img"
                    :src="thisProduct.managerImgUrl"
                  />
                  <span>{{ thisProduct.managerName }}</span>
                </div>
              </el-col>
            </el-row>
            <div class="line"></div>
            <el-row :gutter="20" type="flex" align="middle">
              <el-col :span="7">
                <div class="label">Social Media Accounts</div>
              </el-col>
              <el-col :span="17">
                <div class="content link-box">
                  <social-media
                    :type="thisProduct.social1"
                    :url="thisProduct.socialUrl1"
                  ></social-media>
                  <social-media
                    :type="thisProduct.social2"
                    :url="thisProduct.socialUrl2"
                  ></social-media>
                  <social-media
                    :type="thisProduct.social3"
                    :url="thisProduct.socialUrl3"
                  ></social-media>
                </div>
              </el-col>
            </el-row>
            <div class="line"></div>
            <el-row :gutter="20" type="flex" align="middle">
              <el-col :span="7">
                <div class="label">Form</div>
              </el-col>
              <el-col :span="17">
                <div class="content">
                  <span>{{ thisProduct.form }}</span>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane
            :label="'\xa0\xa0' + 'Manager Profiles' + '\xa0\xa0'"
            name="manager"
          >
            <div class="manager-title">
              <img
                v-if="thisProduct.managerImgUrl"
                class="manager-img"
                :src="thisProduct.managerImgUrl"
              />
              {{ thisProduct.managerName }}
            </div>
            <div class="content">
              <p v-html="thisProduct.managerDesStr"></p>
            </div>
          </el-tab-pane>
          <el-tab-pane :label="'\xa0\xa0' + 'Fee' + '\xa0\xa0'" name="fee">
            <el-row :gutter="20" type="flex" align="middle">
              <el-col :span="7">
                <div class="label">Mint Fee:</div>
              </el-col>
              <el-col :span="17">
                <div class="content">{{ thisProduct.mintFee * 100 }} %</div>
              </el-col>
            </el-row>
            <div class="line"></div>
            <el-row :gutter="20" type="flex" align="middle">
              <el-col :span="7">
                <div class="label">Performance Fee:</div>
              </el-col>
              <el-col :span="17">
                <div class="content">
                  {{ thisProduct.performanceFee * 100 }} %
                </div>
              </el-col>
            </el-row>
            <div class="line"></div>
            <el-row :gutter="20" type="flex" align="middle">
              <el-col :span="7">
                <div class="label">Burn Fee</div>
              </el-col>
              <el-col :span="17">
                <div class="content">
                  <p>
                    {{ thisProduct.burnFee[0].fee * 100 }} %, if the holding
                    period is less than {{ thisProduct.burnFee[0].max }} hours
                  </p>
                  <p>
                    {{ thisProduct.burnFee[1].fee * 100 }} %, if the holding
                    period is less than {{ thisProduct.burnFee[1].max }} hours
                  </p>
                  <p>
                    {{ thisProduct.burnFee[2].fee * 100 }} %, if the holding
                    period is more than {{ thisProduct.burnFee[2].min }} hours
                  </p>
                </div>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane
            :label="'\xa0\xa0' + 'Investors' + '\xa0\xa0'"
            name="Investors"
          >
            <div class="tabsDiv">
              <el-table
                :data="investorsData"
                style="width: 100%; height: 100%"
                header-row-class-name="header_table"
                row-class-name="row_table"
                header-cell-class-name="header_cell_table"
                cell-class-name="cell_table"
                class="ranking_table1 mbfs12"
              >
                <el-table-column width="420" label="INVESTOR">
                  <template slot-scope="scope">
                    <div>
                      <span>{{ scope.row.depositorShow }}</span>
                      <i
                        class="el-icon-document-copy investor-copy"
                        style="cursor: pointer"
                        v-clipboard:success="onCopy"
                        v-clipboard:copy="scope.row.depositor"
                      ></i>
                      <i
                        class="el-icon-share investor-copy"
                        @click="viewOnSolscan(scope.row.depositor)"
                      ></i>
                      <div
                        class="inv-btn"
                        v-if="scope.row.depositor == manager.publicKey"
                      >
                        Manager
                      </div>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column label="NUMBER OF SHARES">
                  <template slot-scope="scope">{{
                    scope.row.shares.toFixed(4)
                  }}</template>
                </el-table-column>
                <el-table-column label="PERCENTAGE">
                  <template slot-scope="scope"
                    >{{ (scope.row.percentage * 100).toFixed(3) }} %</template
                  >
                </el-table-column>
                <template slot="empty">
                  <div class="noData">No available Data</div>
                </template>
              </el-table>
            </div>
          </el-tab-pane>
          <el-tab-pane
            :label="'\xa0\xa0' + 'Mint/Burn' + '\xa0\xa0'"
            name="mint-burn"
          >
            <div class="tabsDiv">
              <el-table
                :data="MintBurnData"
                style="width: 100%; height: 100%"
                header-row-class-name="header_table"
                row-class-name="row_table"
                header-cell-class-name="header_cell_table"
                cell-class-name="cell_table"
                class="ranking_table1 mbfs12"
              >
                <el-table-column label="ADDRESS">
                  <template slot-scope="scope"
                    >{{ scope.row.feePayer.slice(0, 5) }}...{{
                      scope.row.feePayer.slice(-5)
                    }}</template
                  >
                </el-table-column>
                <el-table-column prop="actionStr" label="ACTION" />
                <el-table-column label="SHARE">
                  <template slot-scope="scope">{{
                    scope.row.amount.toFixed(2)
                  }}</template>
                </el-table-column>
                <el-table-column label="VALUE">
                  <template slot-scope="scope"
                    >$
                    {{
                      (scope.row.price * scope.row.amount).toFixed(2)
                    }}</template
                  >
                </el-table-column>
                <el-table-column label="DATE">
                  <template slot-scope="scope">{{
                    scope.row.date.toLocaleString()
                  }}</template>
                </el-table-column>
                <template slot="empty">
                  <div class="noData">No available Data</div>
                </template>
              </el-table>
            </div>
          </el-tab-pane>
          <el-tab-pane
            :label="'\xa0\xa0' + 'Transactions' + '\xa0\xa0'"
            name="Transactions"
          >
            <div class="tabsDiv">
              <el-table
                :data="activityData"
                style="width: 100%; height: 100%"
                header-row-class-name="header_table"
                row-class-name="row_table"
                header-cell-class-name="header_cell_table"
                cell-class-name="cell_table"
                class="ranking_table1 mbfs12"
                :key="trankey"
              >
                <el-table-column width="200px" label="SIGNATURE">
                  <template slot-scope="scope">
                    {{ scope.row.signatureShow }}
                    <i
                      class="el-icon-document-copy investor-copy"
                      style="cursor: pointer"
                      v-clipboard:success="onCopy"
                      v-clipboard:copy="scope.row.signature"
                    ></i>
                    <i
                      class="el-icon-share investor-copy"
                      @click="viewOnSolscanTx(scope.row.signature)"
                      v-if="scope.row.type === 1 || scope.row.type === 2"
                    ></i>
                  </template>
                </el-table-column>
                <el-table-column label="PROTOCOL">
                  <template slot-scope="scope">
                    <span v-if="scope.row.type == 1">Transfer</span>
                    <span v-else-if="scope.row.type == 2">Serum Swap</span>
                    <span v-else-if="scope.row.type == 3">Wootrade Sell</span>
                    <span v-else-if="scope.row.type == 4">Wootrade Buy</span>
                    <span v-else-if="scope.row.type == 6">
                      Wootrade Deposit
                    </span>
                    <span v-else-if="scope.row.type == 7">
                      Wootrade Withdraw
                    </span>
                    <span v-else>Unknow</span>
                  </template>
                </el-table-column>
                <el-table-column min-width="270px" label="TRADE">
                  <template slot-scope="scope">
                    <activity-pair :activity="scope.row"></activity-pair>
                  </template>
                </el-table-column>
                <el-table-column label="RATE">
                  <template slot-scope="scope">
                    {{ scope.row.rate.toFixed(4) }}
                  </template>
                </el-table-column>
                <el-table-column prop="dateShow" label="DATE" />
                <template slot="empty">
                  <div>No available Data</div>
                </template>
              </el-table>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>
    </div>

    <sap-dialog :small="small" :userAuthority="userAuthority" ref="sapDialog" />
    <trade-dialog
      :small="small"
      ref="tradeDialog"
      :minta="mintName"
      mintb="SYP"
    />
    <div class="empty_height" />
  </div>
</template>

<script>
import Activities from "@/components/activities/activities.vue";
import {
  getAssetValueComposition,
  getAssetValueSYComposition,
} from "@/assets/js/SYcalculate";
import { monthsRev } from "@/assets/js/oracleList";
import { Connection, PublicKey } from "@solana/web3.js";
import SapDialog from "../components/sapDialog.vue";
import { rpcConfig, rpcUrl } from "../assets/js/index";
import {
  getTransactionList,
  sapProgramId,
  syProgramId,
  getSapData,
  getSyData,
} from "../assets/js/sap/sap";
import moment from "moment";
import SocialMedia from "../components/socialMedia.vue";
import ActivityPair from "../components/activityPair.vue";
import AssetIcon from "../components/assetIcon3.vue";
import TradeDialog from "../components/tradeDialog.vue";
import { linearFilter } from "../assets/js/linearFilter";
import { getTokenName, getTokenInfo } from "../assets/js/token";
const Smooth = 0.5;

var { WOW } = require("wowjs");
export default {
  name: "SAP Detail",
  components: {
    Activities,
    SapDialog,
    SocialMedia,
    ActivityPair,
    AssetIcon,
    TradeDialog,
  },
  data() {
    return {
      connection: new Connection(rpcUrl, rpcConfig),
      walletKey: "",
      showBTC: false,
      range: "day",
      echartDate: [],
      echartShowData: [],
      BtcData: [],
      BtcDate: [],
      barData: [],
      mint: "",
      pubkey: "",
      chartMin: 0,
      chartMax: 1000,
      init_date: "2021 Aug 18",
      marketCap: 0,
      dateWindow: "day",
      thisProduct: {
        name: "SYPOOL",
        manager: "Sypool",
        marketCap: 0,
        mint: "",
        init_date: "2021 Aug 18",
        price: 0,
        managerName: "",
        managerImgUrl: "",
        managerDesStr: "",
        description: "",
        descriptionImg: "",
        social1: 0,
        socialUrl1: "",
        social2: 0,
        socialUrl2: "",
        social3: 0,
        socialUrl3: "",
        form: "",
        mintFee: 0,
        performanceFee: 0,
        burnFee: [{}, {}, {}],
        // composition:''
      },
      priceChart: "",
      pieChart: "",
      productPrice: 0,
      oneDayReturn: 0,
      oneMonthReturn: 0,
      oneYearReturn: 0,
      basePrice: 100,
      tableDataTransaction: [],
      trankey: false,
      risk: "",
      desriptionData: [
        { condition: "Quant SAP loss( -100%,-34% )", r1: "-", r2: "-" },
        {
          condition: "Quant SAP loss( -34%,6% )",
          r1: "10%",
          r2: "( -100%,0% )",
        },
        {
          condition: "Quant SAP loss( 6%,+inf )",
          r1: "10%",
          r2: "( 0%,+inf )",
        },
      ],
      tabs: ["MINT", "BURN", "TRADE", "FARM", "STAKING", "CLAIM"],
      protfolioData: [],
      positionData: [],
      investorsData: [],
      activityData: [],
      walletKey: "",
      userAuthority: 0,
      MintBurnData: [],
      manager: {
        name: "",
        publicKey: "",
      },
    };
  },
  async mounted() {
    if (this.$store.state.walletConnectInfo) {
      // this.connection = this.$store.state.walletConnectInfo.connection;
      this.walletKey = this.$store.state.walletConnectInfo.publicKey.toBase58();
      this.getUserAuthority();
    }
    this.$nextTick(() => {
      new WOW({ animateClass: "animate__animated" }).init();
    });
    this.mint = this.$route.query.mint;
    this.pubkey = this.$route.query.pubkey;
    let result = await this.$axios.post("/getOneProduct", {
      mint: this.mint,
      pubkey: this.pubkey,
    });
    let fee = 0;
    if (result.data.data == undefined) {
      this.$alert("cannot find sap", "Alert", {
        confirmButtonText: "Ok",
        confirmButtonClass: "alert_btn_ok",
      });
      return;
    } else {
      this.getSapActivity();
      let product = result.data.data;
      this.thisProduct = product;
      this.init_date = new moment(product.initialized_date).format(
        "YYYY MM DD"
      );
      if (product.type == 0) {
        // get price, get cap, supply, composition
        let sypoolRes = await getAssetValueComposition(
          this.connection,
          product,
          product.mint[0]
        );
        let price = sypoolRes[0];
        let total = sypoolRes[1];
        let supply = sypoolRes[2];
        let composition = sypoolRes[3];
        product.price = parseFloat(price);
        this.marketCap = this.splitValue(parseInt(Math.round(total)));
        product.marketCap = this.marketCap;
        product.init_date = this.init_date;
        product.supply = parseFloat(supply);
        product.composition = composition;
        product.mint2 = product.mint[0];
        this.thisProduct = product;
        this.risk = product.risk;
        let res = await getSapData(this.connection, product.pubkey);
        if (res.code == 1) {
          let sapData = res.data;
          fee += sapData.fee;
          fee += sapData.performanceFee;
        }
      } else if (product.type == 1) {
        let index = product.mint.indexOf(this.mint);
        if (index == -1) {
          this.$alert("cannot find this SAP", "Alert", {
            confirmButtonText: "Ok",
            confirmButtonClass: "alert_btn_ok",
          });
          return;
        } else {
          let sypoolRes = await getAssetValueSYComposition(
            this.connection,
            rpcUrl,
            product,
            product.mint
          );
          let price = sypoolRes[0][index];
          // let total = sypoolRes[1];
          let supply = sypoolRes[2][index];
          let composition = sypoolRes[3];
          let values = sypoolRes[4];
          product.price = parseFloat(price);
          this.marketCap = this.splitValue(Math.round(values[index]));
          product.marketCap = this.marketCap;
          product.init_date = this.init_date;
          product.supply = parseFloat(supply);
          product.composition = composition;
          product.mint2 = product.mint[index];
          if (index == 1) {
            product.risk = "High";
          } else if (index == 0) {
            product.risk = "Low";
          }
          this.risk = product.risk;
          this.thisProduct = product;
          let res = await getSyData(this.connection, product.pubkey);
          if (res.code == 1) {
            let sapData = res.data;
            if (sapData.status < 2) {
              this.init_date = "Hasn't start";
            }
            fee += sapData.fee;
            fee += sapData.performanceFee;
          }
        }
      }
    }
    this.getSapHistory(1);
    this.echartsBarInit();
    if (result.data.code == 1) {
      let sapPriceList = result.data.sapPrice;
      let product = result.data.data;
      this.getProtfolioData(sapPriceList, fee, product);
      if (product.enableWoo) this.getPositionData(product);
      this.manager = result.data.manager;
    }
    let lastDayPrice = await this.$axios.post("/getLastPriceByPubkey", {
      pubkey: this.pubkey,
      mint: this.mint,
      addTime: new Date(new Date().getTime() - 24 * 3600000),
    });
    let lastMonthPrice = await this.$axios.post("/getLastPriceByPubkey", {
      pubkey: this.pubkey,
      mint: this.mint,
      addTime: new Date(new Date().getTime() - 30 * 24 * 3600000),
    });
    let lastYearPrice = await this.$axios.post("/getLastPriceByPubkey", {
      pubkey: this.pubkey,
      mint: this.mint,
      addTime: new Date(new Date().getTime() - 365 * 24 * 3600000),
    });
    if (lastDayPrice.data.data != null) {
      let oneDayPrice = parseFloat(lastDayPrice.data.data.price);
      this.oneDayReturn = parseFloat(
        ((oneDayPrice - this.basePrice) / this.basePrice) * 100
      );
    }
    if (lastMonthPrice.data.data != null) {
      let oneMonthPrice = parseFloat(lastMonthPrice.data.data.price);
      this.oneMonthReturn = parseFloat(
        ((oneMonthPrice - this.basePrice) / this.basePrice) * 100
      );
    }
    if (lastYearPrice.data.data != null) {
      let oneYearPrice = parseFloat(lastYearPrice.data.data.price);
      this.oneYearReturn = parseFloat(
        ((oneYearPrice - this.basePrice) / this.basePrice) * 100
      );
    }
    // this.getTransactionHistory('');
    this.getInvestorsData(this.connection, this.mint);
    this.getMintBurnData();
  },
  methods: {
    onCopy() {
      // this.$message({ message: "Copy success", type: "success" });
      this.$notify({
        title: "Success",
        message: "Copy success.",
        type: "success",
      });
    },
    gotoLink(url) {
      window.open(url, "_blank");
    },
    gotoPools() {
      this.$router.push("/exploremain");
    },
    splitValue(value) {
      let t = value.toString();
      let final = [];
      let count = 0;
      for (var i = t.length - 1; i >= 0; i--) {
        if (count % 3 == 0) {
          if (i != t.length - 1) {
            final.push(",");
          }
        }
        final.push(t[i]);
        count += 1;
      }

      let rev = final.reverse();
      return rev.join("");
    },
    small() {},
    openDialog(action) {
      this.$refs.sapDialog.activeName = action;
      this.$refs.sapDialog.productSelect = parseInt(this.$route.query.index);
      this.$refs.sapDialog.dialogVisible = true;
    },
    openDialogTrade() {
      // this.$refs.sapDialog.activeName = action;
      // this.$refs.sapDialog.productSelect = parseInt(this.$route.query.index);
      this.$refs.tradeDialog.dialogVisible = true;
    },

    jumpToLink() {
      const path =
        "https://raydium.io/swap/?inputCurrency=FnKE9n6aGjQoNWRBZXy4RW6LZVao7qwBonUbiD7edUmZ&outputCurrency=HWSqJdwemji7TNiKQPudUj86LXyF3vGAtWm5ePk5KzgD&inputAmount=0&outputAmount=0&fixed=in";
      window.open(path, "_blank").focus();
    },

    async getSapActivity() {
      try {
        let res = await this.$axios.get("/getFundActivity", {
          params: {
            publicKey: this.pubkey,
          },
        });
        if (res.data.code == 1) {
          let activityList = [];
          let data = res.data.data;
          for (let i = 0; i < data.length; i++) {
            let temp = {
              date: new Date(data[i].time),
              dateShow: moment(data[i].time).format("YYYY-MM-DD"),
              token: data[i].token,
              rate: 1 / data[i].price,
              amount: data[i].amount,
              investor: data[i].manager,
              signature: data[i].signature,
              signatureShow: "",
              type: data[i].type,
              token1: "",
              token1icon: "",
              amount1: 0,
              token2: "",
              token2icon: "",
              amount2: 0,
            };
            temp.signatureShow =
              data[i].signature.slice(0, 5) +
              "..." +
              data[i].signature.slice(
                data[i].signature.length - 5,
                data[i].signature.length - 1
              );
            // 1 transfer in
            // 2 swap
            // 3 woo sell 4 woo buy 5 woo other
            // 6 transfer to woo 7 transfer from woo
            if (data[i].type == 1) {
              temp.token1 = data[i].token;
              temp.token1icon = data[i].token;
              temp.amount1 = data[i].amount;
            } else if (data[i].type == 2) {
              let tokenPair = data[i].token.split("/");
              temp.token1 = tokenPair[0];
              temp.token1icon = tokenPair[0];
              temp.amount1 = data[i].amount;
              if (tokenPair.length > 1) {
                temp.token2 = tokenPair[1];
                temp.token2icon = tokenPair[1];
                temp.amount2 = data[i].amount * data[i].price;
              } else {
                temp.token1 = tokenPair[0];
                temp.token1icon = tokenPair[0];
                temp.amount1 = data[i].amount;
              }
            } else if (data[i].type == 3) {
              temp.rate = data[i].price;
              let tokenPair = data[i].token.split("_");
              if (tokenPair.length === 3) {
                temp.token1 = `${tokenPair[1]}-${tokenPair[0]}`;
                temp.token1icon = tokenPair[1];
                temp.token2 = tokenPair[2];
                temp.token2icon = tokenPair[2];
              } else if (tokenPair.length === 2) {
                temp.token1 = tokenPair[0];
                temp.token1icon = tokenPair[0];
                temp.token2 = tokenPair[1];
                temp.token2icon = tokenPair[1];
              }
              temp.amount1 = data[i].amount;
              temp.amount2 = data[i].amount * data[i].price;
            } else if (data[i].type == 4) {
              temp.rate = data[i].price;
              let tokenPair = data[i].token.split("_");
              if (tokenPair.length === 3) {
                temp.token2 = `${tokenPair[1]}-${tokenPair[0]}`;
                temp.token2icon = tokenPair[1];
                temp.token1 = tokenPair[2];
                temp.token1icon = tokenPair[2];
              } else if (tokenPair.length === 2) {
                temp.token2 = tokenPair[0];
                temp.token2icon = tokenPair[0];
                temp.token1 = tokenPair[1];
                temp.token1icon = tokenPair[1];
              }
              temp.amount1 = data[i].amount * data[i].price;
              temp.amount2 = data[i].amount;
            }
            activityList.push(temp);
          }
          activityList.sort((a, b) => {
            return b.date - a.date;
          });
          this.activityData = activityList;
        } else {
          console.error("get fund activity error", res.data);
        }
      } catch (error) {
        console.error("get fund activity error", error);
      }
    },
    async getSapHistory(init) {
      let chartData = await this.$axios.post("/getSapHistoryData", {
        mint: this.mint,
        pubkey: this.pubkey,
        range: this.dateWindow,
      });
      if (chartData.data.sapMinPrice != undefined) {
        let data = chartData.data.sapMinPrice;
        let sapDate = data.map((e) => {
          return new Date(e.addTime).getTime();
        });
        let dates = data.map((e) => {
          return this.formatDate2(new Date(e.addTime));
        });
        let dates1 = data.map((e) => {
          return this.formatDate4(new Date(e.addTime));
        });
        let values = data.map((e) => {
          return e.price;
        });
        values = linearFilter(values);
        this.productPrice = values[values.length - 1];
        let std = this.sd(values);
        this.chartMax = Math.ceil(Math.max(...values) + 5 * std);
        this.chartMin = Math.floor(Math.min(...values) - 5 * std);
        this.echartShowData = values;
        this.echartDate = dates;
        this.BtcDate = [dates1[0], dates1[dates1.length - 1]];
        const btc = await this.$axios.post("/getSymboHistoryData", {
          // type: 'Price',
          asset: "BTC/USD",
          range: this.dateWindow,
          // "startDate": this.BtcDate[0],
          // "endDate": this.BtcDate[1],
        });
        let btcData = btc.data.data.map((e) => {
          return e.price;
        });
        let btcDate = btc.data.data.map((e) => {
          return new Date(e.addTime).getTime();
        });
        let btcData1 = [];
        let j = -1;
        for (let i = 0; i < sapDate.length - 1; i++) {
          let currentBtcData = 0;
          let currentBtcDate;
          if (j < 0) {
            currentBtcData = btcData[0];
            currentBtcDate = btcDate[0];
          } else if (j > btcData.length - 1) {
            currentBtcData = btcData[btcData.length - 1];
            currentBtcDate = btcDate[btcData.length - 1];
          } else {
            currentBtcData = btcData[j];
            currentBtcDate = btcDate[j];
          }
          btcData1.push(currentBtcData);
          if (sapDate[i] > currentBtcDate) {
            j++;
          }
        }
        btcData1.push(btcData[btcData.length - 1]);
        this.BtcData = btcData1;

        if (init) {
          this.priceEchartsInit();
        } else {
          if (!this.priceChart) {
            this.priceEchartsInit();
          }
          if (this.showBTC) {
            this.refreshEchart1();
          } else {
            this.refreshEchart();
          }
        }
      }
    },
    convert(Uint8Arr) {
      var length = Uint8Arr.length;

      let buffer = Buffer.from(Uint8Arr);
      var result = buffer.readUIntLE(0, length);

      return result;
    },
    async getTransactionHistory(beforeSign) {
      // let pubLen = 32;
      let transactionHistory = [];
      for (var i = 0; i < 1; i++) {
        let programId = "";
        if (this.thisProduct.type == 0) {
          programId = sapProgramId;
        } else if (this.thisProduct.type == 1) {
          programId = syProgramId;
        }
        let option = { limit: 20 };
        if (beforeSign != "") {
          option.before = beforeSign;
        }
        let transSignatures =
          await this.connection.getConfirmedSignaturesForAddress2(
            new PublicKey(programId),
            option
          );
        // console.log('transaction', transSignatures);
        for (let k = 0; k < transSignatures.length; k++) {
          const signature = transSignatures[k].signature;
          const confirmedTransactions =
            await this.connection.getConfirmedTransaction(signature);
          let thisTranKeys = confirmedTransactions.transaction.keys;
          let action = this.convert(
            confirmedTransactions.transaction.data.slice(0, 1)
          );
          let mint = thisTranKeys[3].toBase58();
          if (action == 2 && this.thisProduct.type == 1) {
            let mint1 = thisTranKeys[2].toBase58();
            let mint2 = thisTranKeys[3].toBase58();
            if (mint1 == this.mint) {
              mint = mint1;
            } else if (mint2 == this.mint) {
              mint = mint2;
            }
          }
          if (this.mint == mint) {
            let user = thisTranKeys[5].toBase58();
            console.log("###", user);
            let amount =
              this.convert(
                confirmedTransactions.transaction.data.slice(33, 41)
              ) /
              10 ** 9;
            if (action == 2 && this.thisProduct.type == 1) {
              amount =
                this.convert(
                  confirmedTransactions.transaction.data.slice(1, 9)
                ) /
                10 ** 9;
            }
            let date = new Date(confirmedTransactions.blockTime * 1000);
            let thisPrice = await this.$axios.post("/getLastPriceByPubkey", {
              mint: this.mint,
              pubkey: this.pubkey,
              addTime: date,
            });
            let value = "NA";
            let price = 100;
            console.log("*@@@@thisPrice", thisPrice);

            if (thisPrice.data.data != undefined) {
              price = thisPrice.data.data.price;
              value = "$" + parseFloat(amount * price).toFixed(2);
            }
            console.log("*###", confirmedTransactions);
            console.log("action", signature, action, amount, price);

            if (action == 1 && this.thisProduct.type == 1) {
              value = "$" + parseFloat(amount * 100).toFixed(2);
            }
            let type = "MINT";
            if (action == 1) {
              type = "MINT";
            } else if (action == 2) {
              type = "BURN";
            }
            if (action == 1 || action == 2) {
              transactionHistory.push({
                date: this.formatDate3(date),
                value: value,
                investor: user,
                transaction: signature,
                type: type,
              });
            }
          }
        }
      }
      // let testData = await this.connection.getTokenLargestAccounts(this.mint);
      this.tableDataTransaction =
        this.tableDataTransaction.concat(transactionHistory);
      // console.log("*$$$$$tableDataTransaction", transactionHistory)
      this.trankey = !this.trankey;
    },
    async getUserAuthority() {
      try {
        let walletAcc = this.$store.state.walletConnectInfo.publicKey;
        let res = await this.$axios.post("/getUserAuthority", {
          publicKey: walletAcc.toBase58(),
        });
        if (res.data.code == 1) {
          let user = res.data.data;
          if (user.authority) {
            // 1 test 0 nomal
            this.userAuthority = user.authority;
          } else {
            this.userAuthority = 0;
          }
        } else {
          console.error("get user authority error", res.data);
        }
      } catch (e) {
        console.error("get user authority error", e);
      }
    },
    async getUserAuthority2() {
      try {
        let wallet = this.$store.state.walletConnectInfo.wallet;
        let res = await getUserTestAuthority(this.connection, wallet);
        if (res.code == 1) {
          this.userAuthority = 1;
        } else {
          this.userAuthority = 0;
        }
      } catch (e) {
        console.error("get user authority error", e);
      }
    },
    async loadMoreTran() {
      await this.getTransactionHistory(
        this.tableDataTransaction[this.tableDataTransaction.length - 1]
          .transaction
      );
      // console.log('***$$$$&&&', this.tableDataTransaction)
    },
    priceEchartsInit() {
      // 找到容器
      const priceChart = this.$echarts.init(
        document.getElementById("price-chart")
      );
      // 开始渲染
      let color;
      if (
        this.echartShowData[this.echartShowData.length - 1] -
          this.echartShowData[0] >
        0
      ) {
        color = "#34D399";
      } else {
        color = "#F87171";
      }
      priceChart.setOption({
        backgroundColor: "#221636",
        grid: {
          bottom: 30,
          left: 40,
          top: 40,
          right: 50,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#1b152b",
          borderWidth: 0,
        },
        xAxis: {
          type: "category",
          data: this.echartDate,
          name: "Date",
          boundaryGap: false,
          axisLabel: {
            show: true,
          },
          axisLine: {
            show: true,
          },
        },
        yAxis: {
          type: "value",
          name: "value",
          axisLabel: {
            show: true,
          },
          axisLine: {
            show: true,
          },
          splitLine: {
            lineStyle: {
              color: "#25242b",
            },
          },
          min: this.chartMin,
          max: this.chartMax,
        },
        series: [
          {
            data: this.echartShowData,
            type: "line",
            color: color,
            smooth: Smooth,
            itemStyle: {
              opacity: 0,
            },
          },
        ],
      });
      this.priceChart = priceChart;
      // window.onresize = this.priceChart.resize;
      window.addEventListener("resize", function () {
        priceChart.resize();
      });
    },
    addBTC() {
      if (this.showBTC) this.refreshEchart1();
      else this.refreshEchart();
    },
    echartsBarInit() {
      let barData = [];
      let barNameList = [];
      let sum = 0;
      for (let i = 0; i < this.thisProduct.composition.length; i++) {
        let value = Math.round(
          parseFloat(this.thisProduct.composition[i].value)
        );
        barData.push({
          value: value,
          name: this.thisProduct.composition[i].token.name,
          info: this.thisProduct.composition[i].token.name + " : ",
          selected: true,
        });
        barNameList.push(this.thisProduct.composition[i].token.name);
        sum += value;
      }
      for (let i = 0; i < this.thisProduct.composition.length; i++) {
        let temp = (barData[i].value / sum) * 100;
        barData[i].info += temp.toFixed(3);
        barData[i].info += "%";
      }
      this.barData = barData;
    },
    getProtfolioData(sapPriceList, fee, product) {
      let sapPrice = sapPriceList[0];
      let sapAssetList = sapPrice.sapAssetList;
      let wooAssetList = sapPrice.wooAssetList;
      let sapPriceYst = sapPriceList[1];
      let sapAssetListYst = sapPriceYst.assetList;
      let wooAssetListYst = sapPriceYst.wooAssetList;
      let protfolioData = [];
      let usdcData;
      let totalValue = 0;
      let tokenList = product.tokenList;
      if (!product.enableWoo) {
        for (let i = 0; i < sapAssetList.length; i++) {
          let asset = sapAssetList[i];
          let name = asset.name;
          let assetYst = sapAssetListYst.find((e) => {
            return e.name == name;
          });
          let change = 0;
          if (assetYst) change = (asset.price - assetYst.price) / asset.price;
          if (!asset.price) change = 0;
          if (isNaN(change)) change = 0;
          let tokenTemp = tokenList[i];
          if (name == "USDC") {
            usdcData = {
              name,
              asset: name,
              price: asset.price,
              value: asset.value - fee,
              balance: (asset.value - fee) / asset.price,
              info: 0,
              change,
              green: change >= 0,
              selected: true,
              img: tokenTemp.img,
            };
          } else {
            let protfolioTemp = {
              name,
              asset: name,
              price: asset.price,
              value: asset.value,
              balance: asset.amount,
              info: 0,
              change,
              green: change >= 0,
              selected: true,
              img: tokenTemp.img,
            };
            protfolioData.push(protfolioTemp);
          }
          totalValue += asset.value;
        }
        totalValue -= fee;
        protfolioData.forEach((e) => {
          e.info = totalValue > 0 ? e.value / totalValue : 0;
        });
        protfolioData = protfolioData.filter((e) => {
          return e.balance > 0.0;
        });
        protfolioData.sort((item1, item2) => {
          return item2.info - item1.info;
        });
        if (usdcData) {
          usdcData.info = totalValue > 0 ? usdcData.value / totalValue : 0;
          protfolioData.push(usdcData);
        }
        this.protfolioData = protfolioData;
      } else {
        let positionData = [];
        let wooTotalValue = 0;
        for (let i = 0; i < wooAssetList.length; i++) {
          let asset = wooAssetList[i];
          let name = asset.name;
          let assetYst = wooAssetListYst.find((e) => {
            return e.name == name;
          });
          let change = 0;
          if (assetYst) change = (asset.price - assetYst.price) / asset.price;
          if (!asset.price) change = 0;
          if (isNaN(change)) change = 0;
          let tokenTemp = getTokenInfo(name);
          if (name == "USDC") {
            usdcData = {
              name,
              asset: name,
              price: asset.price,
              value: asset.value,
              balance: asset.value / asset.price,
              info: 0,
              change,
              green: change >= 0,
              selected: true,
              img: tokenTemp.logoURI,
            };
          } else {
            let protfolioTemp = {
              name,
              asset: name,
              price: asset.price,
              value: asset.value,
              balance: asset.amount,
              info: 0,
              change,
              green: change >= 0,
              selected: true,
              img: tokenTemp.logoURI,
            };
            positionData.push(protfolioTemp);
          }
          wooTotalValue += asset.value;
        }
        positionData.forEach((e) => {
          e.info = wooTotalValue > 0 ? e.value / wooTotalValue : 0;
        });
        positionData = positionData.filter((e) => {
          return e.balance > 0.0;
        });
        positionData.sort((item1, item2) => {
          return item2.info - item1.info;
        });
        if (usdcData) {
          usdcData.info =
            wooTotalValue > 0 ? usdcData.value / wooTotalValue : 0;
          positionData.push(usdcData);
        }
        this.protfolioData = positionData;
      }
    },
    async getPositionData(product) {
      let res = await this.$axios.get("/getFundWooPosition", {
        params: { publicKey: product.pubkey },
      });
      if (res.status === 200) {
        if (res.data.code === 1) {
          let data = res.data.data;
          this.positionData = data.positionList.filter((e) => {
            return e.holding != 0;
          });
        }
      }
    },
    changeRange(ind) {
      if (ind != this.dateWindow) {
        if (ind == 0) {
          this.dateWindow = "hour";
        }
        if (ind == 1) {
          this.dateWindow = "day";
        }
        if (ind == 2) {
          this.dateWindow = "week";
        }
        if (ind == 3) {
          this.dateWindow = "month";
        }
        if (ind == 4) {
          this.dateWindow = "year";
        }
        this.getSapHistory();
      }
    },
    refreshEchart(chartid) {
      let color;
      if (
        this.echartShowData[this.echartShowData.length - 1] -
          this.echartShowData[0] >
        0
      ) {
        color = "#34D399";
      } else {
        color = "#F87171";
      }
      this.priceChart.setOption(
        {
          grid: {
            bottom: 30,
            left: 40,
            top: 40,
            right: 50,
          },
          tooltip: {
            trigger: "axis",
            backgroundColor: "#1b152b",
            borderWidth: 0,
          },
          xAxis: {
            type: "category",
            data: this.echartDate,
            name: "Date",
            boundaryGap: false,
            axisLabel: {
              show: true,
            },
            axisLine: {
              show: true,
            },
          },
          yAxis: {
            type: "value",
            name: "value",
            axisLabel: {
              show: true,
            },
            axisLine: {
              show: true,
            },
            splitLine: {
              lineStyle: {
                color: "#25242b",
              },
            },
            min: this.chartMin,
            max: this.chartMax,
          },
          series: [
            {
              data: this.echartShowData,
              type: "line",
              color: color,
              smooth: Smooth,
              itemStyle: {
                opacity: 0,
              },
            },
          ],
        },
        true
      );
      this.priceChart.resize();
    },
    refreshEchart1(chartid) {
      let color1;
      let color2;
      if (
        this.echartShowData[this.echartShowData.length - 1] -
          this.echartShowData[0] >
        0
      ) {
        color1 = "#34D399";
      } else {
        color1 = "#F87171";
      }
      if (this.BtcData[this.BtcData.length - 1] - this.BtcData[0] > 0) {
        color2 = "#FFD700";
      } else {
        color2 = "#FFD700";
      }
      // this.BtcData = [1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1,1]
      let data0 = [];
      let data1 = [];
      let min1 =
        (this.echartShowData[1] - this.echartShowData[0]) /
        this.echartShowData[0];
      let min2 = (this.BtcData[1] - this.BtcData[0]) / this.BtcData[0];
      let max1 =
        (this.echartShowData[1] - this.echartShowData[0]) /
        this.echartShowData[0];
      let max2 = (this.BtcData[1] - this.BtcData[0]) / this.BtcData[0];
      for (let i = 1; i < this.echartShowData.length; i++) {
        let temp =
          (this.echartShowData[i] - this.echartShowData[0]) /
          this.echartShowData[0];
        min1 = min1 < temp ? min1 : temp;
        max1 = max1 > temp ? max1 : temp;
        data0.push(temp);
      }
      for (let i = 1; i < this.BtcData.length; i++) {
        let temp = (this.BtcData[i] - this.BtcData[0]) / this.BtcData[0];
        min2 = min2 < temp ? min2 : temp;
        max2 = max2 > temp ? max2 : temp;
        data1.push(temp);
      }
      let min = min1 < min2 ? min1 : min2;
      let max = max1 > max2 ? max1 : max2;
      this.priceChart.setOption(
        {
          grid: {
            bottom: 30,
            left: 60,
            top: 40,
            right: 50,
          },
          tooltip: {
            trigger: "axis",
            formatter: function (params) {
              let html = params[0].name + "<br>";
              for (let i = 0; i < params.length; i++) {
                html +=
                  '<span style="display:inline-block;margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:' +
                  params[i].color +
                  ';"></span>';
                html +=
                  params[i].seriesName +
                  ":" +
                  (params[i].value * 100).toFixed(2) +
                  "%<br>";
              }
              return html;
            },
            backgroundColor: "#1b152b",
            borderWidth: 0,
          },
          legend: {
            // data: ['price', 'BTC comparison'],
            textStyle: {
              color: "white",
            },
          },
          xAxis: {
            type: "category",
            data: this.echartDate,
            name: "Date",
            boundaryGap: false,
            axisLabel: {
              show: true,
            },
            axisLine: {
              show: true,
            },
          },
          yAxis: {
            type: "value",
            name: "value",
            axisLabel: {
              show: true,
              formatter: function (v) {
                return (v * 100).toFixed(2) + "%";
              },
            },
            axisLine: {
              show: true,
            },
            splitLine: {
              lineStyle: {
                color: "#25242b",
              },
            },
            min: min - 0.15 * max,
            // max: Math.ceil(this.BtcData[0] * 1.2)
            max: max + 0.15 * max,
          },
          series: [
            {
              name: this.computedProductName,
              data: data0,
              type: "line",
              color: color1,
              smooth: Smooth,
              label: {
                formatter: function (v) {
                  return (v * 100).toFixed(2) + "%";
                },
              },
              itemStyle: {
                opacity: 0,
              },
            },
            {
              name: "Bitcoin",
              data: data1,
              type: "line",
              color: color2,
              smooth: 0.75,
              label: {
                formatter: function (v) {
                  return (v * 100).toFixed(2) + "%";
                },
              },
              itemStyle: {
                opacity: 0,
              },
              // smooth:true
            },
          ],
        },
        true
      );
      this.priceChart.resize();
    },
    add0(m) {
      return m < 10 ? "0" + m : m;
    },
    formatDate(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = monthsRev[time.getMonth()];
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return y + " " + this.add0(m) + " " + this.add0(d);
    },

    formatDate2(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return (
        // y +
        // "-" +
        this.add0(m) +
        "-" +
        this.add0(d) +
        " " +
        this.add0(h) +
        ":" +
        this.add0(mm) //+
        // ":" +
        // this.add0(s)
      );
    },
    formatDate3(shijianchuo) {
      //shijianchuo是整数，否则要parseInt转换
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth() + 1;
      var d = time.getDate();
      var h = time.getHours();
      var mm = time.getMinutes();
      var s = time.getSeconds();
      return (
        y +
        "-" +
        this.add0(m) +
        "." +
        this.add0(d) +
        " " +
        this.add0(h) +
        ":" +
        this.add0(mm) //+
        // ":" +
        // this.add0(s)
      );
    },
    formatDate4(shijianchuo) {
      var time = new Date(shijianchuo);
      var y = time.getFullYear();
      var m = time.getMonth();
      var d = time.getDate();
      return y + "-" + this.add0(m + 1) + "-" + this.add0(d);
    },
    sd(numbers) {
      const mean = numbers.reduce((acc, n) => acc + n) / numbers.length;
      return Math.sqrt(
        numbers.reduce((acc, n) => (n - mean) ** 2) / numbers.length
      );
    },
    async getInvestorsData(connection, mint) {
      let res = await connection.getTokenLargestAccounts(new PublicKey(mint));
      let userSapList = res.value;
      let sapAcc = await connection.getTokenSupply(new PublicKey(mint));
      let supply = sapAcc.value.uiAmount;
      let investorList = [];
      for (let i = 0; i < userSapList.length; i++) {
        let res2 = await connection.getParsedAccountInfo(
          userSapList[i].address
        );
        let depositor = res2.value.data.parsed.info.owner;
        let shares = userSapList[i].uiAmount;
        if (shares > 0) {
          let investor = investorList.find((e) => {
            return e.depositor == depositor;
          });
          if (!investor) {
            let investorNew = {
              depositor: depositor,
              depositorShow:
                depositor.slice(0, 6) +
                "..." +
                depositor.slice(depositor.length - 4, depositor.length),
              shares,
              percentage: userSapList[i].uiAmount / supply,
            };
            investorList.push(investorNew);
          } else {
            investor.shares += shares;
          }
        }
      }
      this.investorsData = investorList;
    },
    viewOnSolscan(path) {
      window.open("https://solscan.io/account/" + path, "_blank");
    },
    viewOnSolscanTx(path) {
      window.open("https://solscan.io/tx/" + path, "_blank");
    },
    async getMintBurnData() {
      let pubkey = this.mint;
      let res = await getTransactionList(this.connection, pubkey, "");
      if (res.length > 0) {
        this.MintBurnData = res.filter((e) => {
          return e.action > 0;
        });
      }
    },
  },
  computed: {
    computedProductName() {
      let temp = this.thisProduct;
      let nameTemp = "";
      if (temp.type == 1) {
        nameTemp = temp.name;
        if (this.risk == "Low") {
          nameTemp += "-R1";
        }
        if (this.risk == "High") {
          nameTemp += "-R2";
        }
      } else {
        nameTemp = temp.name;
      }
      return nameTemp;
    },
    mintName() {
      return getTokenName(this.mint);
    },
  },
};
</script>

<style lang="less" scoped>
.view {
  position: relative;

  .back-btn {
    position: absolute;
    top: 0;
    left: 0;
    width: 50px;
    height: 50px;
    line-height: 50px;
    color: white;
    text-align: center;
    font-size: 25px;
    cursor: pointer;

    @media (max-width: 980px) {
      top: 10px;
      left: 15px;
    }
  }

  .inner {
    width: 80%;
    margin: 0 auto;
    @media (max-width: 980px) {
      width: 100%;
    }
  }
}

.box1 {
  display: flex;
  align-items: center;
  .logo {
    height: 80px;
    width: 80px;
    > img {
      height: 100%;
      width: 100%;
      object-fit: cover;
    }
  }
  .name {
    margin-left: 25px;
    .title {
      font-size: 32px;
      font-weight: bold;
    }
  }
}

.box2 {
  margin-top: 35px;
  .item {
    margin-right: 25px;
    padding: 10px 0;
    width: 240px;
    text-align: center;
    font-size: 24px;
    background-color: #131315;
    border-radius: 5px;
    box-shadow: 5px 3px 3px #000;
    @media (max-width: 980px) {
      margin-right: 0;
      margin-top: 25px;
    }
  }
}

.box3 {
  margin-top: 35px;
  padding: 15px;
  background-color: #131315;
  border-radius: 15px;
  box-shadow: 5px 3px 3px #000;
  .title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: 980px) {
      flex-direction: column;
      justify-content: flex-end;
      align-items: flex-end;
    }
    .btn-box {
      display: flex;
      align-items: center;
      .switch-group {
        border-radius: 999px;
        overflow: hidden;
      }
      .switch-btn {
        display: inline-block;
        width: 50px;
        padding: 10px;
        box-sizing: border-box;
        cursor: pointer;
        font-size: 14px;
        background-color: #1e2442;
        transition: all 0.4s;
        text-align: center;
        &:hover {
          background-color: #0096cd;
        }
        &.at {
          background-color: #0096cd;
        }
      }
    }
  }
}

.box4 {
  margin: 35px auto 0;
  width: 80%;
  @media (max-width: 980px) {
    width: 100%;
  }
  .dialog-btn {
    text-align: center;
  }
}

.box5 {
  margin-top: 35px;
  .title {
    font-size: 24px;
    margin-bottom: 20px;
  }
}

.box6 {
  margin-top: 35px;
  padding: 20px 30px;
  background-color: #131315;
  border-radius: 15px;
  .label {
    font-size: 18px;
  }
  .content {
    font-size: 18px;
    line-height: 35px;
  }
  .line {
    margin: 20px 0;
    border-bottom: 2px solid #1b152b;
  }
  .manager-img {
    width: 30px;
    position: relative;
    top: 10px;
    margin-right: 5px;
  }
  .link-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 75%;
  }
  .manager-title {
    margin-bottom: 35px;
    font-size: 18px;
  }
}

.inv-btn {
  height: 20px;
  width: 58px;
  margin-left: 10px;
  padding: 0;
  font-size: 12px;
  border-radius: 10px;
  display: inline-block;
  background-color: #67c23a;
  text-align: center;
  line-height: 20px;
}
</style>
