export function linearFilter(data) {
  let n = data.length;
  if (n < 7) {
    return data;
  }
  let dataOut = [];
  dataOut.fill(0, 0, n);
  dataOut[0] =
    (13.0 * data[0] +
      10.0 * data[1] +
      7.0 * data[2] +
      4.0 * data[3] +
      data[4] -
      2.0 * data[5] -
      5.0 * data[6]) /
    28.0;
  dataOut[1] =
    (5.0 * data[0] +
      4.0 * data[1] +
      3.0 * data[2] +
      2.0 * data[3] +
      data[4] -
      data[5]) /
    14.0;
  dataOut[2] =
    (7.0 * data[0] +
      6.0 * data[1] +
      5.0 * data[2] +
      4.0 * data[3] +
      3.0 * data[4] +
      2.0 * data[5] +
      1.0 * data[6]) /
    28.0;
  for (let i = 3; i <= n - 4; i++) {
    dataOut[i] =
      (data[i - 3] +
        data[i - 2] +
        data[i - 1] +
        data[i] +
        data[i + 3] +
        data[i + 2] +
        data[i + 1]) /
      7.0;
  }
  dataOut[n - 3] =
    (7.0 * data[n - 1] +
      6.0 * data[n - 2] +
      5.0 * data[n - 3] +
      4.0 * data[n - 4] +
      3.0 * data[n - 5] +
      2.0 * data[n - 6] +
      1.0 * data[n - 7]) /
    28.0;
  dataOut[n - 2] =
    (5.0 * data[n - 1] +
      4.0 * data[n - 2] +
      3.0 * data[n - 3] +
      2.0 * data[n - 4] +
      data[n - 5] -
      data[n - 6]) /
    14.0;
  dataOut[n - 1] =
    (13.0 * data[n - 1] +
      10.0 * data[n - 2] +
      7.0 * data[n - 3] +
      4.0 * data[n - 4] +
      data[n - 5] -
      2.0 * data[n - 6] -
      5.0 * data[n - 7]) /
    28.0;
  return dataOut;
}
