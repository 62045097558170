// import { TokenListProvider } from '@solana/spl-token-registry';

// var tokenList = [];
const tokenList = require("./tokenList.json");

export function getTokenInfo(tokenName) {
  return tokenList.find((e) => {
    return e.symbol == tokenName;
  });
}

// async function initTokenList() {
//     let TokenListCDN = await new TokenListProvider().resolve();
//     console.log('token list', TokenListCDN);
//     tokenList = TokenListCDN.getList();
// }

// initTokenList();
