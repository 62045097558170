<template>
  <span class="activity-pair">
    <el-row>
      <el-col :span="3" class="icon">
        <asset-icon :asset="activity.token1icon"></asset-icon>
      </el-col>
      <el-col :span="6">
        <el-row>
          <el-col>{{ activity.token1 }}</el-col>
        </el-row>
        <el-row>
          <el-col>{{ activity.amount1.toFixed(3) }}</el-col>
        </el-row>
      </el-col>
      <el-col :span="2" v-if="activity.token2 != ''">
        <i class="el-icon-right"></i>
      </el-col>
      <el-col :span="3" v-if="activity.token2 != ''" class="icon">
        <asset-icon :asset="activity.token2icon"></asset-icon>
      </el-col>
      <el-col :span="6" v-if="activity.token2 != ''">
        <el-row>
          <el-col>{{ activity.token2 }}</el-col>
        </el-row>
        <el-row>
          <el-col>{{ activity.amount2.toFixed(3) }}</el-col>
        </el-row>
      </el-col>
    </el-row>
  </span>
</template>

<script>
import AssetIcon from "./assetIcon2.vue";

export default {
  name: "Activity Pair",
  components: {
    AssetIcon
  },
  props: {
    activity: Object
  }
};
</script>

<style lang="less" scoped>
.activity-pair {
  font-size: 16px;
  line-height: 23px;
  font-weight: 400;
  color: white;
  .icon {
    padding-top: 10px;
    padding-right: 5px;
  }
}
</style>