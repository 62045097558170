<!--
 * @Description:
 * @Author: zhuxiaobing
 * @Date: 2021-06-27 17:30:16
 * @LastEditors: zhuxiaobing
 * @LastEditTime: 2021-06-27 17:57:14
-->
<template>
  <div class="ranking">
    <el-table
      :data="tableData"
      style="width: 100%"
      max-height="500"
      header-row-class-name="header_table"
      row-class-name="row_table"
      header-cell-class-name="header_cell_table"
      cell-class-name="cell_talbe"
      class="ranking_table"
    >
      <el-table-column prop="Date" label="Date" />
      <el-table-column prop="Value" label="Value" />
      <el-table-column prop="Investor" label="Investor" />
      <el-table-column prop="Transaction" label="Transaction" />
      <el-table-column prop="Type" label="Type" />
    </el-table>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          Date: "2021.06.15 18:37",
          Value: "$10026",
          Investor: "111",
          Transaction: "+480%",
          Type: "Mint"
        }
      ]
    };
  }
};
</script>

<style lang="less" scoped>
</style>
